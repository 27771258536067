class LifeVineUsers {
    private lv: LifeVine;
    private basePath: string;
    private _bookings: LifeVineBookings;
    private _dependents: LifeVineDependents;
    private _locations: LifeVineLocations;

    constructor(lv: LifeVine) {
        this.lv = lv;
        this.basePath = '/users';
        this._bookings = new LifeVineBookings(this.lv);
        this._dependents = new LifeVineDependents(this.lv);
        this._locations = new LifeVineLocations(this.lv);
    }

    public bookings(id) {
        return this._bookings.pass(this.getPath(id));
    }

    public dependents(id) {
        return this._dependents.pass(this.getPath(id));
    }

    public locations(id) {
        return this._locations.pass(this.getPath(id));
    }

    public get(id?: number|null, options?: LifeVineUsersOptions) {
        let promise;
        if (options) {
            promise = this.lv.ajax('GET', this.getPath(id), options);
        } else {
            promise = this.lv.ajax('GET', this.getPath(id));
        }
        return promise;
    }

    public create(data) {
        return this.lv.ajax('POST', this.getPath(), data);
    }

    public save(id: number, data) {
        return this.lv.ajax('PUT', this.getPath(id), data);
    }

    public remove(id: number) {
        return this.lv.ajax('DELETE', this.getPath(id));
    }

    public resetPassword(id: number) {
        return this.lv.ajax('POST', this.getPath(id) + '/reset-password', {});
    }

    private getPath(id?: any) {
        return id ? `${this.basePath}/${id}` : this.basePath;
    }
}

interface LifeVineUsersOptions {
    user_type?: string;
}